export function setMsgErro(msg, callback){
    callback({msg: msg, variant: 'danger'});
}

export function setMsgInfo(msg, callback){
    callback({msg: msg, variant: 'info'});
}

export function formataNomeArquivo(nome){
    if(nome){
        let separatorIdx = nome.indexOf('!');
        if(separatorIdx && separatorIdx!==-1){
            return nome.substring(separatorIdx+1);
        }
    }
    return nome;
}

export function validateEmail(mail) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(mail);
}

export function formatPhoneNumber(phoneNumber)  {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');

    let match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
    if (match) {
        return `(${match[1]}) ${match[2]} - ${match[3]}`;
    }

    match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
    if (match) {
        return `(${match[1]}) ${match[2]} - ${match[3]}`;
    }

    return phoneNumber;
}

export function isValidPhoneNumber(phoneNumber){
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');

    return cleaned.length === 10 || cleaned.length === 11;
}

export function removeNonNumeric(value) {
    return value.replace(/\D/g, '');
}