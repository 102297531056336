import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../../../resources/img/logo-dias-nogueira.webp';
import '../../../../resources/css/navbar.css'
import { LinkContainer } from "react-router-bootstrap";

const NavBar = props => (
  <div className="navbar-top">
    <Navbar expand="lg" bg="dark" data-bs-theme="dark" className="custom-navbar-top">
      <Container>
        <LinkContainer to={{pathname: "/", hash: "#home",}}>
          <Navbar.Brand href="#home"><img src={logo} height={100} alt="Logo Dias Nogueira"/></Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" >
          <Nav className="justify-content-end" style={{ width: "100%" }}>
            <LinkContainer to={{pathname: "/", hash: "#home",}}>
              <Nav.Link href="#home">Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/servicos">
              <Nav.Link href="#servicos">Áreas de Atuação</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/tipo-processo">
              <Nav.Link href="#consulta">Consultar Processos</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/envio-documento">
              <Nav.Link >Envio de Documentos</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/modelos">
              <Nav.Link href="#modelos">Modelos</Nav.Link>
            </LinkContainer>
            <Nav.Link href="https://blog.diasenogueira.com.br" target="_blank" rel="noopener noreferrer">Publicações</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </div>
);
 
export default NavBar;