import React, {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import imgQuemSomos from '../../../../resources/img/dn-quem-somos.jpg';
import {endpointPosts, setFooterDark, urlHost} from "../../../../utils/Constants";
import {useLocation} from "react-router-dom";
import {LinkContainer} from "react-router-bootstrap";
import axios from "axios";
import placeholderCapa from "../../../../resources/img/company-placeholder.webp";
import Slider from 'react-slick';

function Home(props) {

    const { pathname, hash, key } = useLocation();
    const [posts, setPosts] = useState([]);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        centerPadding: '55px',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '0px'
                }
            }
        ]
    };

    useEffect(() => {
        setFooterDark(true);
        if (hash === '') {
            window.scrollTo(0, 0);
        }else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
    }, [pathname, hash, key]);

    useEffect(() => {

        const loadPosts = () => {
            axios.get(urlHost + endpointPosts)
                .then(response => {
                    if (response.status === 200) {
                        setPosts(response.data);
                    }
                }).catch(error => {
                console.log(error);
            });
        }

        loadPosts();

    }, []);

    return(
        <div id='home'>
            <div className="overlay-text-image-container">
                <div className="centered" >
                    <span className="overlay-text">
                        ESCRITÓRIO ESPECIALIZADO EM CONTRIBUIR ATIVAMENTE NO AUXÍLIO
                        JUDICIAL DE PROCESSOS SUCESSÓRIOS E DE INSOLVÊNCIA EMPRESARIAL
                    </span>
                </div>
                <br/>
                <div className="bottom-centered arrasta-cima">
                    Arraste para cima
                    <br/>
                    <span className="fa fa-solid fa-angle-down"></span>
                </div>
                <span className="background-image-page-header-overlay"></span>
            </div>

            <div id='quem-somos' className="site-section dark fill-height-rows pattern-right text-align-center">
                <Container>
                    <Row className="justify-content-center text-center">
                        <div className="site-section-title mb-5">Quem Somos</div>

                        <img className="mt-2 mb-5 img-responsive" src={imgQuemSomos} style={{height: 'auto', border: 'none'}} alt='Imagem do Quem Somos'/>
                    </Row>
                    <Row className="align-items-center justify-content-center">
                        <p className="site-section-text mt-4" style={{textAlign: 'justify'}}>
                            A DIAS & NOGUEIRA é uma empresa criada para atuação especializada em Recuperações
                            Judiciais e Falências em todo território nacional.

                            <br></br>Formada por uma equipe de profissionais altamente capacitados e com
                            especialidades singulares em diversas áreas (Direito, Administração, Ciências
                            Contábeis, etc.), oferecemos a resolução célere e criativa das demandas judiciais relacionadas
                            a insolvência para além daquelas instituídas na Lei 11.101/2005.

                            <br></br><br></br>A experiência de nossos profissionais em assessorias de magistrados
                            nos permitiu desenvolver um método de trabalho que auxilia de forma objetiva tanto a
                            assessoria quanto o magistrado condutor do processo, eliminando o desnecessário e
                            adicionando o que é essencial em nossos pareceres e relatórios, sempre embasados,
                            objetivos e claros, auxiliando o Judiciário na tomada de decisões seguras.

                            <br></br><br></br>Além disso, nossa atuação pautada na ética e no respeito às normas
                            legais fortalece a credibilidade da equipe de administradores judiciais perante os
                            credores, os colaboradores e os demais envolvidos no processo de insolvência.
                        </p>
                    </Row>
                    <div className="text-align-center">
                        <LinkContainer to={'/equipe'}>
                            <Button className='equipe btn-sm mt-3 text-align-center' variant='primary'>Saiba mais</Button>
                        </LinkContainer>
                    </div>
                </Container>
            </div>
            <div id="artigos" className="site-section fill-height-rows pattern-left text-align-center">
                <Container>
                    <Row className="justify-content-center text-center">
                        <div className="site-section-title mb-5">Artigos</div>
                    </Row>
                    <Slider {...settings}>
                        {posts.map(post => (
                            <div key={post.id} className="card-post mt-4 w-100">
                                <figure className="card-post-figure mt-3 mb-3" onClick={() => window.open(`${post.pathPost}`, '_blank')}>
                                    <img
                                        src={post.pathCapa ? `${post.pathCapa}` : placeholderCapa}
                                        alt={`${post.titulo}`}
                                        className='card-post-img'
                                    />
                                </figure>
                                <figcaption className="card-post-content">
                                    <h1>{post.titulo}</h1>
                                </figcaption>
                            </div>
                        ))}
                    </Slider>
                </Container>
            </div>
        </div>
    )
}

export default Home;